import {DateTimeErrorType} from '@Components/event-wizard/event-wizard-date-time/event-wizard-date-time.types';
import {convertTimeFromTimezoneToUTC, hasDatePassed2, hasTimePassed} from '@Utils/date.util';
import type {TimeZone} from 'timezones-list';

export const getDateTimeErrorType = (startDateTime: Date, endDateTime: Date, timezone: TimeZone): null | DateTimeErrorType => {
  const endDateTimeUTC = convertTimeFromTimezoneToUTC(endDateTime, timezone.utc);

  if (startDateTime >= endDateTime) {
    if (startDateTime.getDate() > endDateTime.getDate()) {
      return DateTimeErrorType.END_DATE_GREATER;
    }
    return DateTimeErrorType.END_TIME_GREATER;
  }

  if (hasDatePassed2(endDateTimeUTC)) {
    if (hasTimePassed(endDateTimeUTC)) {
      return DateTimeErrorType.EVENT_PASSED_TIME;
    }
    return DateTimeErrorType.EVENT_PASSED_DATE;
  }
  return null;
};

export const getDateTimeErrorText = (startDateTime: Date, endDateTime: Date, timezone: TimeZone): string => {
  const errType: null | DateTimeErrorType = getDateTimeErrorType(startDateTime, endDateTime, timezone);

  if (errType === null) {
    return '';
  }

  if (errType === DateTimeErrorType.EVENT_PASSED_DATE) {
    return window.i18next.t('pmwjs_event_date_passed');
  }

  if (errType === DateTimeErrorType.EVENT_PASSED_TIME) {
    return window.i18next.t('pmwjs_event_time_passed');
  }

  if (errType === DateTimeErrorType.END_DATE_GREATER) {
    return window.i18next.t('pmwjs_end_date_greater_than_start');
  }
  return window.i18next.t('pmwjs_end_time_greater_than_start');
};
