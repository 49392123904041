import type {EventWizardReduxState} from '@Components/event-wizard/event-wizard.types';
import type {RecurringEvent} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {RecurringType} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import type {PlaceDetails} from '@Components/google-map/google-map.types';
import type {EventRegistrationFields} from '@Components/event-wizard/event-wizard-registrations/event-wizard-registrations.types';
import type {OrganiserDetails} from '@Components/event-wizard/event-wizard-organisers/event-wizard-organisers.types';
import type {CoverPhoto} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.types';
import type {EventStyles} from '@Components/event-wizard/event-style/event-style.types';
import {redirectUser} from '@Utils/browser.util';
import type {EventWizardVenue} from '@Components/event-wizard/event-wizard-venue/event-wizard-venue.types';
import {EventVenueType, isOfflineVenueComplete} from '@Components/event-wizard/event-wizard-venue/event-wizard-venue.types';
import type {EventVO} from '@Components/events/events.types';
import {
  getDefaultRecurringEvent,
  onEventWizardCoverPhotoUpdate,
  onEventWizardDescriptionChange,
  onEventWizardEndDateTimeUpdate,
  onEventWizardIsUpdatingEvent,
  onEventWizardOfflineVenueChange,
  onEventWizardOnlineVenueChange,
  onEventWizardRecurringChange,
  onEventWizardRegistrationChange,
  onEventWizardRemoveWatermarkToggle,
  onEventWizardSelectedOrganiserUpdate,
  onEventWizardSelectedTypeChange,
  onEventWizardShareAsGoogleEventToggle,
  onEventWizardShareInTeamToggle,
  onEventWizardStartDateTimeUpdate,
  onEventWizardStyleUpdate,
  onEventWizardTimeZoneUpdate,
  onEventWizardTitleChange,
  onEventWizardToggleCountdown,
} from '@Components/event-wizard/event-wizard-slice';
import type {TimeZone} from 'timezones-list';
import timezones from 'timezones-list';
import {convertUTCTimeToGivenTimezone, getTimeDifferenceIntervals} from '@Utils/date.util';
import {getUserPremiumLevel} from '@Libraries/user.library';
import {USER_PREMIUM_LEVELS} from '@Utils/user.util';
import {getDateTimeErrorType} from '@Components/event-wizard/event-wizard-date-time/event-wizard-date-time.helper';
import {DateTimeErrorType} from '@Components/event-wizard/event-wizard-date-time/event-wizard-date-time.types';

/**
 * Checks if an element has a class or is inside a parent class
 * @param element
 * @param classNameToExclude
 */
export const isElementInClass = (element: HTMLElement | null, classNameToExclude: string): boolean => {
  let elementRecur = element;

  while (elementRecur) {
    if (elementRecur.classList.contains(classNameToExclude)) {
      return true;
    }
    elementRecur = elementRecur.parentElement;
  }
  return false;
};

// selfCodeReviewJibran: PAYG or premium +? Confirm all flows
export const shouldUpsellUser = (): boolean => {
  return getUserPremiumLevel() === USER_PREMIUM_LEVELS.PAYG;
};

export const setReduxForUpdatingEvent = (eventVO: EventVO): void => {
  const {dispatch} = window.PMW.redux.store;

  dispatch(onEventWizardIsUpdatingEvent(eventVO.hashedID));
  dispatch(onEventWizardTitleChange(eventVO.title));
  dispatch(onEventWizardSelectedTypeChange(eventVO.type));
  dispatch(onEventWizardDescriptionChange(eventVO.description));
  dispatch(onEventWizardCoverPhotoUpdate({uploadedUrl: eventVO.img}));
  setReduxVenueFromUpdateEvent(eventVO);
  setReduxRecurringForUpdateEvent(eventVO.recurring);
  const timezone = setReduxTimezoneFromUpdateEvent(eventVO.tzcode);
  setReduxDateTimeFromUpdateEvent(new Date(eventVO.startDateTime), new Date(eventVO.endDateTime), timezone);
  dispatch(onEventWizardToggleCountdown(eventVO.showCountdown));
  dispatch(onEventWizardStyleUpdate(eventVO.style));
  setReduxOrganiserForUpdateEvent(eventVO);
  dispatch(onEventWizardShareInTeamToggle(eventVO.isShared));
  dispatch(onEventWizardRemoveWatermarkToggle(eventVO.isRemoveWatermark));
  dispatch(onEventWizardRegistrationChange(eventVO.registrationFields));
  dispatch(onEventWizardShareAsGoogleEventToggle(!!eventVO.isSharedOnGoogle));
};

export const doesContainKeywords = (searchText: string, keywords: string): boolean => {
  return searchText.toLowerCase().includes(keywords.toLowerCase());
};

export const addHoursToDate = (date: Date, hours: number = 1): Date => {
  return new Date(date.getTime() + hours * 60 * 60 * 1000);
};

export const saveEvent = async (data: EventWizardReduxState): Promise<void> => {
  return window.PMW.writeLocal('event/saveEvent', prepareDataForEventCreation(data)).then((eventHid: string) => {
    redirectUser(window.PMW.util.site_url(`e/${eventHid}?fw=1`), false, false);
  });
};

const setReduxVenueFromUpdateEvent = (eventVO: EventVO): void => {
  const {dispatch} = window.PMW.redux.store;

  if (eventVO.venueOnline) {
    dispatch(onEventWizardOnlineVenueChange(eventVO.venueOnline.link));
    return;
  }

  if (eventVO.venue && isOfflineVenueComplete(eventVO.venue)) {
    dispatch(onEventWizardOfflineVenueChange(eventVO.venue));
  }
};

const setReduxTimezoneFromUpdateEvent = (tzcode: string): TimeZone => {
  for (const timezone of timezones) {
    if (timezone.tzCode === tzcode) {
      window.PMW.redux.store.dispatch(onEventWizardTimeZoneUpdate(timezone));
      return timezone;
    }
  }
  return timezones[0]; // selfCodeReviewJibran: backup should be user current timezone?
};

const setReduxRecurringForUpdateEvent = (recurring: RecurringEvent | undefined): void => {
  if (!recurring?.recurringEndDateStr) {
    return;
  }
  recurring.recurringEndDate = new Date(recurring.recurringEndDateStr);

  const defaultRecurring = getDefaultRecurringEvent();

  if (recurring.recurringType === RecurringType.WEEKLY) {
    recurring.recurringMonthly = defaultRecurring.recurringMonthly;
  } else if (recurring.recurringType === RecurringType.MONTHLY) {
    recurring.recurringWeeks = defaultRecurring.recurringWeeks;
  } else {
    recurring.recurringWeeks = defaultRecurring.recurringWeeks;
    recurring.recurringMonthly = defaultRecurring.recurringMonthly;
  }
  window.PMW.redux.store.dispatch(onEventWizardRecurringChange({...recurring, isRecurring: true}));
};

const setReduxDateTimeFromUpdateEvent = (startDateTime: Date, endDateTime: Date, timeZone: TimeZone): void => {
  const {dispatch} = window.PMW.redux.store;

  const errType = getDateTimeErrorType(startDateTime, endDateTime, timeZone);
  let startDateTimeUpdated: Date;
  let endDateTimeUpdated: Date;

  if (errType && errType !== DateTimeErrorType.EVENT_PASSED_DATE && errType !== DateTimeErrorType.EVENT_PASSED_TIME) {
    return;
  }

  if (errType) {
    const {intervalInDays} = getTimeDifferenceIntervals((endDateTime.getTime() - startDateTime.getTime()) / 1000);
    startDateTimeUpdated = new Date(startDateTime.getTime());
    startDateTimeUpdated.setDate(new Date().getDate() + 1);
    endDateTimeUpdated = new Date(endDateTime.getTime());
    endDateTimeUpdated.setDate(startDateTimeUpdated.getDate() + intervalInDays);
  } else {
    startDateTimeUpdated = startDateTime;
    endDateTimeUpdated = endDateTime;
  }
  dispatch(onEventWizardStartDateTimeUpdate(convertUTCTimeToGivenTimezone(startDateTimeUpdated, timeZone.utc)));
  dispatch(onEventWizardEndDateTimeUpdate(convertUTCTimeToGivenTimezone(endDateTimeUpdated, timeZone.utc)));
};

const setReduxOrganiserForUpdateEvent = (eventVO: EventVO): void => {
  if (!eventVO.organiser) {
    return;
  }
  const {dispatch} = window.PMW.redux.store;
  dispatch(onEventWizardSelectedOrganiserUpdate({...eventVO.organiser, isProfilePicturePrefilled: !!eventVO.organiser.profilePic}));
};

const prepareDataForEventCreation = (data: EventWizardReduxState) => {
  const venueData = getVenueData(data.venue);
  const registrationFields = getRegistrationFieldsData(data.registrationDetails);
  const organiserData = getOrganisationData(data.organisers.selectedOrganiser);
  const coverPhotoData = getCoverPhotoData(data.coverPhoto);
  const styles = getStyleData(data.styles);

  return {
    event_hid: data.updatingEventHID,
    title: data.title,
    event_type_hid: data.types?.selectedType?.hashedID,
    description: data.description,
    ...coverPhotoData,
    ...venueData,
    date_start: data.startDateTime.toLocaleString(),
    date_end: data.endDateTime.toLocaleString(),
    recurring_pattern: getRecurringPatternForPost(data.recurringEvent),
    timezone: data.timeZone.tzCode,
    show_countdown: data.showCountdown ? 1 : 0,
    ...styles,
    ...organiserData,
    is_shared_team: data.isSharedInTeam ? 1 : 0,
    is_remove_watermark: data.isRemoveWatermark ? 1 : 0,
    is_shared_google: data.isSharedAsGoogleEvent ? 1 : 0,
    ...registrationFields,
    is_duplicate: data.isDuplicate ? 1 : 0,
  };
};

const getVenueData = (venue: EventWizardVenue) => {
  if (venue.selectedVenueType === EventVenueType.OFFLINE) {
    return getOfflineVenueData(venue.offlineVenue);
  }

  if (venue.selectedVenueType === EventVenueType.ONLINE) {
    return getOnlineVenueData(venue.onlineVenueURL);
  }
};

const getOnlineVenueData = (url: string | undefined) => {
  if (!url) {
    return null;
  }

  return {
    online_event_link: url,
  };
};

const getOfflineVenueData = (place: PlaceDetails | undefined) => {
  if (!place) {
    return null;
  }

  return {
    offline_venue: {
      venue_id: place.idAddress,
      venue_new_place_id: place.placeID,
      name: place.name,
      address: place.address,
      address_directions: place.addressDirections,
      lng: place.longitude,
      lat: place.latitude,
      country: place.country,
      province: place.province,
      city: place.city,
      postal_code: place.postalCode,
    },
  };
};

const getRegistrationFieldsData = (registrationFields: EventRegistrationFields) => {
  return {
    registration_fields: {
      name: registrationFields.name ? 1 : 0,
      phone: registrationFields.phone ? 1 : 0,
      register_btn_text: registrationFields.registerBtnText,
    },
  };
};

const getOrganisationData = (organiserDetails: OrganiserDetails) => {
  return {
    organiser_details: {
      hid: organiserDetails.hashedId,
      name: organiserDetails.name,
      email: organiserDetails.email,
      phone: organiserDetails.phone,
      pic: organiserDetails.profilePic,
      isPicPrefilled: organiserDetails.isProfilePicturePrefilled ? 1 : 0,
      socialMediaLinks: organiserDetails.socialMediaLinks,
    },
  };
};

const getRecurringPatternForPost = (recurringEvent: RecurringEvent) => {
  if (!recurringEvent.isRecurring) {
    return null;
  }
  return {
    recurring_type: recurringEvent.recurringType.toString(),
    recurring_end_date: recurringEvent.recurringEndDate.toLocaleString(),
    recurring_weeks: recurringEvent.recurringWeeks ? recurringEvent.recurringWeeks.join(',') : null,
    recurring_month_weekday: recurringEvent.recurringMonthly?.weekday,
    recurring_month_weekday_num: recurringEvent.recurringMonthly?.weekdayNum,
  };
};

const getCoverPhotoData = (coverPhoto: CoverPhoto) => {
  return {
    uploaded_url: coverPhoto.uploadedUrl,
  };
};

const getStyleData = (eventStyles: EventStyles) => {
  return {
    style: {
      colors: {
        primary: eventStyles.colors.primary,
        light: eventStyles.colors.light,
        lighter: eventStyles.colors.lighter,
        dark: eventStyles.colors.dark,
      },
      font: eventStyles.fontFamily,
    },
  };
};
