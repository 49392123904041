export interface EventWizardRegistrationsProps {
  // add props here
}

export interface EventRegistrationFields {
  email: boolean;
  name: boolean;
  phone: boolean;
  registerBtnText: string;
}

export const getRegistrationButtonTextOptions = (): Array<string> => {
  return [
    window.i18next.t('pmwjs_register'),
    window.i18next.t('pmwjs_sign_up'),
    window.i18next.t('pmwjs_rsvp'),
    window.i18next.t('pmwjs_join'),
    window.i18next.t('pmwjs_claim_spot'),
  ];
};

export const registerTextDropdownID = 'RegisterTextDropdownID';
