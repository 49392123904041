import type {PlaceDetails} from '@Components/google-map/google-map.types';
import {isValidURL} from '@Utils/url.util';
import {getOnlineVenueErrorText} from '@Components/event-wizard/venue-online/venue-online.types';

export interface EventWizardVenueProps {
  // add props here
}

export interface EventWizardVenue {
  selectedVenueType?: EventVenueType;
  onlineVenueURL?: string;
  offlineVenue?: PlaceDetails;
}

export enum EventVenueType {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export const isEventVenueComplete = (venue: EventWizardVenue): boolean => {
  if (venue.selectedVenueType === EventVenueType.OFFLINE && isOfflineVenueComplete(venue.offlineVenue)) {
    return true;
  }
  return (venue.selectedVenueType === EventVenueType.ONLINE && isOnlineVenueComplete(venue.onlineVenueURL)) as boolean;
};

export const isOfflineVenueComplete = (venue: PlaceDetails | undefined): boolean => {
  return !!(venue && venue.name?.trim() && venue.address?.trim());
};

export const isOnlineVenueComplete = (url: string | undefined): boolean => {
  return !!url && isValidURL(url);
};

export const getEventVenueErrorText = (venue: EventWizardVenue): string => {
  if (venue.selectedVenueType === EventVenueType.OFFLINE && isOfflineVenueComplete(venue.offlineVenue)) {
    return window.i18next.t('pmwjs_please_add_venue');
  }

  if (venue.selectedVenueType === EventVenueType.ONLINE) {
    return getOnlineVenueErrorText(venue.onlineVenueURL);
  }
  return window.i18next.t('pmwjs_please_add_venue');
};
