import {getCompatibleImageFileExtension} from '@Utils/image.util';
import {repoURL} from '@Libraries/s3-library';
import {getWriteBucket} from '@Utils/s3.util';

// TODO: Remove this and only have user-image-library.ts. Reanme that to a better name too
/**
 * S3 directory names for images
 * @type {string}
 */
const IMAGE_BUCKET = 'uploads';
const IMAGE_THUMB_SIZE_BUCKET = `${IMAGE_BUCKET}/thumbs`;
const REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION = '_removedbg';
const REMOVED_BG_IMAGE_EXTENSION = 'png';
const REMOVED_BG_DIRECTORY_SCREENS = 'screens';

export const repoImageScreenURL = (hashedFilename: string, fileExtension: string) => {
  if (hashedFilename && fileExtension) {
    if (fileExtension === 'svg') {
      fileExtension = 'png';
    }

    return repoURL(`${IMAGE_BUCKET}/${hashedFilename}.${getCompatibleImageFileExtension(fileExtension)}`, getWriteBucket());
  }
  return '';
};

export const repoImageThumbURL = (hashedFilename: string, fileExtension: string) => {
  if (hashedFilename && fileExtension) {
    if (fileExtension === 'svg') {
      fileExtension = 'png';
    }

    return repoURL(`${IMAGE_THUMB_SIZE_BUCKET}/${hashedFilename}.${getCompatibleImageFileExtension(fileExtension)}`, getWriteBucket());
  }
  return '';
};

export const getRemovedBgURLFromSourceImage = (sourceURLCopy: string) => {
  if (!sourceURLCopy) {
    return;
  }

  const removeBGURL = sourceURLCopy.replace(REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION, '');
  const splitted = removeBGURL.split('.');
  const filePathSplitted = replaceURLDirectoryToRemoveBGDirectory(splitted);
  if (filePathSplitted) {
    const splittedParsed = addRemoveBGExtensionsToSourceURL(filePathSplitted, splitted);
    return splittedParsed.join('.');
  }
};

const addRemoveBGExtensionsToSourceURL = (filePathSplitted: Array<string>, splitted: Array<string>) => {
  splitted[splitted.length - 2] = filePathSplitted.join('/');
  splitted[splitted.length - 2] += REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION;
  splitted[splitted.length - 1] = REMOVED_BG_IMAGE_EXTENSION;
  return splitted;
};

const replaceURLDirectoryToRemoveBGDirectory = (splitted: Array<string>) => {
  const filePathSplitted = splitted[splitted.length - 2].split('/');
  if (filePathSplitted.length !== 4) {
    return;
  }
  filePathSplitted[filePathSplitted.length - 2] = REMOVED_BG_DIRECTORY_SCREENS;
  return filePathSplitted;
};
